import { defineStore } from 'pinia';
import { USER_TOKEN, ACCOUNT_ID } from '@/config/constants';

export const useAuthStore = defineStore('AuthStore', {
  state: () => {
    return {
      userIsLogged: false,
      userToken: null
    };
  },
  getters: {},
  actions: {
    setUserToken(token: string) {
      this.userToken = token;
      this.userIsLogged = true;
      localStorage.setItem(USER_TOKEN, token);
    },
    logout() {
      localStorage.removeItem(USER_TOKEN);
      this.$reset();
    }
  }
});
