import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { qrTypesInterface } from '@/models/qr.model';

export const useQrBuilderStore = defineStore('QrBuilderFormRoute', () => {
  const qrType = ref<qrTypesInterface>(null);

  const setQrType = (type: qrTypesInterface) => (qrType.value = type);

  return { qrType, setQrType };
});
