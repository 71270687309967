import { App } from 'vue';
import { FoldersRepository } from './folders/folders.repository';
import { UsersRepository } from './users/users.repository';

export default {
  install: (app: App) => {
    app.provide('UserRepository', new UsersRepository());
    app.provide('FolderRepository', new FoldersRepository());
  }
};
