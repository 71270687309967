import type { qrTypesInterface } from '@/models/qr.model';
import { defineStore } from 'pinia';

export const useConfigWebStore = defineStore('ConfigWebStore', {
  state: () => {
    return {
      homeQrTypeSelected: 'menu' as qrTypesInterface
    };
  }
});
