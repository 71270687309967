import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { CreateFolderDto } from './folders.dto';
import { FolderModel, FolderWithTotalQr } from '@/models/folder.model';

export class FoldersRepository extends AxiosFactory {
  constructor() {
    super('/folders');
  }

  async createFolder(folder_data: CreateFolderDto): Promise<CustomAxiosResponse<FolderModel>> {
    return await this.axiosApi.post('/', { ...folder_data });
  }

  async getFoldersCurrentAccount({ order_by }): Promise<CustomAxiosResponse<FolderWithTotalQr[]>> {
    return await this.axiosApi.get('', { params: { order_by } });
  }

  async deleteFolder(folder_id: string, delete_qr: boolean): Promise<CustomAxiosResponse<string>> {
    return await this.axiosApi.delete('/' + folder_id, {
      data: {
        delete_qr: delete_qr
      }
    });
  }
}
