import { UserModel } from '@/models/user.model';
import { isEmpty } from 'lodash';
import { defineStore } from 'pinia';

export const useUserStore = defineStore('UserStore', {
  state: () => {
    return {
      user_email: null as UserModel['user_email'],
      user_name: null as UserModel['user_name'],
      user_post_name: null as UserModel['user_post_name'],
      user_language: null as UserModel['user_language'],
      user_profile_image: null as UserModel['user_profile_image']
    };
  },
  getters: {
    getFullName: state => {
      if (state.user_name && state.user_post_name) return `${state.user_name} ${state.user_post_name}`;
      if (state.user_name && !state.user_post_name) return `${state.user_name}`;
      if (!state.user_name && state.user_post_name) return `${state.user_post_name}`;
      return null;
    },
    haveProfileImage: state => !!state.user_profile_image,
    getLiteNameOrProfileImage: state => {
      let liteName: string = null;
      if (state.user_profile_image && !isEmpty(state.user_profile_image)) return state.user_profile_image;
      if (state.user_name && state.user_post_name) liteName = `${state.user_name[0]}${state.user_post_name[0]}`;
      if (state.user_name && !state.user_post_name) liteName = `${state.user_name[0]}${state.user_name[1]}`;
      if (!state.user_name && !state.user_post_name && state.user_email) liteName = `${state.user_email[0]}${state.user_email[1]}`;
      if (liteName) return liteName.toUpperCase();
      return null;
    }
  },
  actions: {
    setDataUser(userData: UserModel) {
      this.user_email = userData.user_email;
      this.user_language = userData.user_language;
      this.user_name = userData.user_name;
      this.user_post_name = userData.user_post_name;
      this.user_profile_image = userData.user_profile_image;
    }
  }
});
