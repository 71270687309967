import axios, { AxiosInstance } from 'axios';
import { ACCOUNT_ID, USER_TOKEN } from '@/config/constants';

export interface CustomAxiosResponse<T> {
  isError: boolean;
  data: T;
  status?: number;
  error?: string | string[];
}

export class AxiosFactory {
  protected axiosApi: AxiosInstance;

  constructor(resource: string) {
    this.axiosApi = axios.create({
      baseURL: process.env.VUE_APP_API + resource,
      timeout: 360000,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    this.axiosApi.interceptors.request.use(
      async config => {
        config.headers = {
          ...config.headers,
          Authorization: 'Bearer ' + localStorage.getItem(USER_TOKEN),
          account_id: localStorage.getItem(ACCOUNT_ID)
        };
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    this.axiosApi.interceptors.response.use(
      response => {
        return {
          ...response.data,
          isError: false
        };
      },
      error => {
        console.log(error);
        return {
          status: error.response.status,
          error: error.response.data?.message ?? error.message,
          isError: true
        };
      }
    );
  }
}
