export const USER_TOKEN = 'qrlytics_user_token';
export const COLAPSE_NAV = 'qrlytics_nav_position';
export const ACCOUNT_ID = 'qrlytics_account_id';

export const LANGUAGES_LIST = [
  {
    language_name: 'Dansk',
    id: 'DA'
  },
  {
    language_name: 'Deutsch',
    id: 'DE'
  },
  {
    language_name: 'English',
    id: 'EN'
  },
  {
    language_name: 'Español',
    id: 'ES'
  },
  {
    language_name: 'Français',
    id: 'FR'
  },
  {
    language_name: 'Italiano',
    id: 'IT'
  },
  {
    language_name: 'Nederlands',
    id: 'NL'
  },
  {
    language_name: 'Norsk',
    id: 'NO'
  },
  {
    language_name: 'Polski',
    id: 'PL'
  },
  {
    language_name: 'Português',
    id: 'PT'
  },
  {
    language_name: 'Suomi',
    id: 'FI'
  },
  {
    language_name: 'Svenska',
    id: 'SV'
  }
];
