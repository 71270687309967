import IconTwoTonesBookSaved from '@/icons/icon-two-tones-book-saved.vue';
import IconTwoTonesTagUser from '@/icons/icon-two-tones-tag-user.vue';
import IconTwoTonesGlobalSearch from '@/icons/icon-two-tones-global-search.vue';
import IconTwoTonesElement4 from '@/icons/icon-two-tones-element-4.vue';
import IconTwoTonesWifi from '@/icons/icon-two-tones-wifi.vue';
import IconTwoTonesVideoPlay from '@/icons/icon-two-tones-video-play.vue';
import IconTwoTonesTask from '@/icons/icon-two-tones-task.vue';
import IconTwoTonesFirstLine from '@/icons/icon-two-tones-first-line.vue';
import IconTwoTonesGallery from '@/icons/icon-two-tones-gallery.vue';
import IconTwoTonesEmail from '@/icons/icon-two-tones-email.vue';
import IconTwoTonesTwitter from '@/icons/icon-two-tones-twitter.vue';
import IconTwoTonesFacebook from '@/icons/icon-two-tones-facebook.vue';
import IconTwoTonesTiktok from '@/icons/icon-two-tones-tiktok.vue';
import IconTowTonesBitcoin from '@/icons/icon-tow-tones-bitcoin.vue';
import IconTwoTonesPaypal from '@/icons/icon-two-tones-paypal.vue';
import IconTwoTonesShop from '@/icons/icon-two-tones-shop.vue';
import { ref } from 'vue';

export const useQrFormats = () => {
  const qrList = ref([
    {
      id: 'menu',
      name: 'Carta/Menú',
      description_short: 'Crea una carta de menú de un restaurante o bar',
      description_long: '',
      icon_component: IconTwoTonesBookSaved
    },
    {
      id: 'vcard',
      name: ' Vcard Plus',
      description_short: 'Comparte detalles de contacto',
      description_long: '',
      icon_component: IconTwoTonesTagUser
    },
    {
      id: 'bussines',
      name: 'Negocio',
      description_short: 'Comparte información sobre tu negocio',
      description_long: '',
      icon_component: IconTwoTonesShop
    },
    {
      id: 'web',
      name: 'Página Web',
      description_short: 'Abre una URL',
      description_long: '',
      icon_component: IconTwoTonesGlobalSearch
    },
    {
      id: 'app',
      name: 'Apps',
      description_short: 'Redirecciona a una tienda de Apps',
      description_long: '',
      icon_component: IconTwoTonesElement4
    },
    {
      id: 'wifi',
      name: 'Wifi',
      description_short: 'Conecta una red WiFi',
      description_long: '',
      icon_component: IconTwoTonesWifi
    },
    {
      id: 'video',
      name: 'Video',
      description_short: 'Reproduce un Video',
      description_long: '',
      icon_component: IconTwoTonesVideoPlay
    },
    {
      id: 'links',
      name: 'Lista de Links',
      description_short: 'Muestra una lista de enlaces',
      description_long: '',
      icon_component: IconTwoTonesTask
    },
    {
      id: 'pdf',
      name: 'PDF',
      description_short: 'Muestra un PDF',
      description_long: '',
      icon_component: IconTwoTonesFirstLine
    },
    {
      id: 'image',
      name: 'Imagenes',
      description_short: 'Muestra una galería de imágenes',
      description_long: '',
      icon_component: IconTwoTonesGallery
    },
    {
      id: 'mail',
      name: 'Email',
      description_short: 'Configura el envio de un email',
      description_long: '',
      icon_component: IconTwoTonesEmail
    },
    {
      id: 'twitter',
      name: 'Twitter',
      description_short: 'Muestra tu Twitter',
      description_long: '',
      icon_component: IconTwoTonesTwitter
    },
    {
      id: 'facebook',
      name: 'Facebook',
      description_short: 'Muestra tu Facebook',
      description_long: '',
      icon_component: IconTwoTonesFacebook
    },
    {
      id: 'tiktok',
      name: 'TikTok',
      description_short: 'Muestra tu TikTok',
      description_long: '',
      icon_component: IconTwoTonesTiktok
    },
    {
      id: 'bitcoin',
      name: 'Bitcoin',
      description_short: 'Cobra con bitcoin',
      description_long: '',
      icon_component: IconTowTonesBitcoin
    },
    {
      id: 'paypal',
      name: 'Paypal',
      description_short: 'Cobra con Paypal',
      description_long: '',
      icon_component: IconTwoTonesPaypal
    },
    {
      id: 'coupon',
      name: 'Cupón',
      description_short: 'Comparte un Cupón',
      description_long: '',
      icon_component: IconTwoTonesPaypal
    },
    {
      id: 'rating',
      name: 'Valoraciones',
      description_short: 'Consigue más valoraciones',
      description_long: '',
      icon_component: IconTwoTonesPaypal
    },
    {
      id: 'maps',
      name: 'Mapa',
      description_short: 'Comparte una posicion del mapa',
      description_long: '',
      icon_component: IconTwoTonesPaypal
    },
    {
      id: 'event',
      name: 'Evento',
      description_short: 'Configura un evento',
      description_long: '',
      icon_component: IconTwoTonesPaypal
    }
  ]);

  return { qrList };
};
