import { UsersRepository } from '../repositories/users/users.repository';
import { inject } from 'vue';
import { useUserStore } from '@/stores/user.store';
import { useAuthStore } from '@/stores/auth.store';
import { useRouter } from 'vue-router';
import { UserModel } from '../models/user.model';
import { useAlertsStore } from '../stores/alerts.store';
import { useAccountStore } from '@/stores/account.store';

export const UseUserActions = () => {
  const UserRepository = inject<UsersRepository>('UserRepository');
  const userStore = useUserStore();
  const accountsStore = useAccountStore();
  const authStore = useAuthStore();
  const alertsStore = useAlertsStore();
  const router = useRouter();

  const getMe = async () => {
    const userDataResponse = await UserRepository.getMe();
    console.log(userDataResponse.data);
    if (userDataResponse.isError) {
      logout();
    } else {
      userStore.setDataUser(userDataResponse.data);
      accountsStore.setListAccounts(userDataResponse.data.accounts);
      return userDataResponse.data;
    }
  };

  const updateContactInfo = async (userData: Partial<UserModel>) => {
    const { isError } = await UserRepository.updateContactData(userData);
    if (isError) {
      alertsStore.createAlert('invalid', 'La información de contacto no se ha actualizado.');
    } else {
      alertsStore.createAlert('correct', 'La información de contacto se ha actualizado correctamente.');
    }
    return await getMe();
  };

  const updateLanguage = async (userLanguage: string) => {
    const { isError } = await UserRepository.updateMeLanguage(userLanguage);
    if (isError) {
      alertsStore.createAlert('invalid', 'El idioma no se ha actualizado.');
    } else {
      //TODO CAMBIAR EL IDIIOMA DE LA APLICACION PRIMERO PARA ENVIAR EN MENSAJE CON EL IDIOMA CORRECTO
      alertsStore.createAlert('correct', 'El idioma se ha actualizado correctamente.');
    }
    return await getMe();
  };

  const updateMePassword = async (last_password: string, new_password: string) => {
    const { isError, error } = await UserRepository.updatePassword(last_password, new_password);
    if (isError) {
      if (error == 'incorrect_last_password') {
        alertsStore.createAlert('invalid', 'La constraseña actual no es correcta.');
      } else {
        alertsStore.createAlert('invalid', 'La constraseña no se ha actualizado.');
      }
    } else {
      alertsStore.createAlert('correct', 'La contraseña se ha actualizado correctamente.');
    }
  };

  const logout = () => {
    authStore.logout();
    userStore.$reset();
    router.push({ name: 'Home' });
  };

  return {
    getMe,
    logout,
    updateContactInfo,
    updateMePassword,
    updateLanguage
  };
};
