import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import VueAnimXyz from '@animxyz/vue3';
import '@animxyz/core';

import './style.scss';

import createContainerRepositories from './repositories/repository.container';

const app = createApp(App);

app.use(createContainerRepositories).use(router).use(createPinia()).use(VueAnimXyz).mount('#app');
