import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { AccountModel } from '@/models/account.model';
import { ACCOUNT_ID } from '@/config/constants';

export const useAccountStore = defineStore('AccountStore', () => {
  const listAccounts = ref<AccountModel[]>([]);

  const isMultiAccount = computed(() => listAccounts.value?.length > 1);

  const accountSelected = ref<string>(localStorage.getItem(ACCOUNT_ID) ?? null);

  const setListAccounts = (accounts: AccountModel[]) => {
    listAccounts.value = accounts;
    if (!accountSelected.value) setAccountSelected(accounts[0]._id);
  };

  const setAccountSelected = (account_id: string) => {
    localStorage.setItem(ACCOUNT_ID, account_id);
    accountSelected.value = account_id;
  };

  return { listAccounts, isMultiAccount, setListAccounts, accountSelected };
});
