import { useScriptTag } from '@vueuse/core';
import { ref } from 'vue';

export type CredentialResponse = google.accounts.id.CredentialResponse;

export interface CredentialResponseParsed {
  sub: string;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  email: string;
}

export const UseGoogleSigOneTap = () => {
  const isLoaded = ref(false);

  useScriptTag(
    'https://accounts.google.com/gsi/client',
    (el: HTMLScriptElement) => {
      isLoaded.value = true;
    },
    {
      async: true,
      defer: true
    }
  );

  const showOneTap = (cb: (response: CredentialResponse) => void) => {
    window.google.accounts.id.initialize({
      client_id: '985389680697-kf5qdtm13hvvql0otd9chou9gq4sdobf.apps.googleusercontent.com',
      context: 'signin',
      auto_select: false,
      cancel_on_tap_outside: false,
      callback: cb
    });
    //eslint-disable-next-line
    window.google.accounts.id.prompt();
  };

  const closeOneTap = () => window.google.accounts.id.cancel();

  const showButtonSignIn = (cb: (response: CredentialResponse) => void) => {
    window.google.accounts.id.initialize({
      client_id: '985389680697-kf5qdtm13hvvql0otd9chou9gq4sdobf.apps.googleusercontent.com',
      callback: cb
    });

    window.google.accounts.id.renderButton(document.getElementById('button-signin-google'), {
      type: 'standard',
      theme: 'filled_blue',
      size: 'large',
      width: '270px'
    });
  };

  const parseRepsonseToken = (token: string): CredentialResponseParsed => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  };

  return {
    isLoaded,
    showOneTap,
    closeOneTap,
    showButtonSignIn,
    parseRepsonseToken
  };
};
